<template>
  <div class="body-content">
    <Loader v-if="is_loading" style="display: flex; align-items: center; justify-content: center" v-bind:logo="loaderLogo" ></Loader>
    <div class="row mb-10">
      <div class="col-xl-12 col-md-12 col-sm-12 mb-3 ddd">
        <p class="table-head last_refreshed" style="font-size: 23px;">
            <router-link to="/">
             <i class="fa fa-home mr-2" style="font-size: 30px; color: #000;"></i>
            </router-link>
     <span class="material-icons" style="margin-left: 0px !important;position: relative;top: 4px;margin-right: 9px;font-size: 21px;">arrow_forward_ios</span>Notifications</p>
      </div>
      <div class="col-xl-12 pt-0 profileInfo card">
        <div class="p-10">
            <!-- <div class="pb-3 n-main-head">Today</div> -->
            <div v-if="notificationList && notificationList.length > 0">
              <div v-for="(item,index) in notificationList" :key="index">
                <!-- <div class="n-card d-flex justify-content-between align-items-center mb-4">
                    <div class="col-md-1 n-card-left d-flex justify-content-center align-items-center">
                        <v-icon>mdi-comment-remove-outline</v-icon>
                    </div>
                    <div class="col-md-10 p-3">
                        <div class="n-card-title pb-1 pt-2">It's awkward</div>
                        <div class="n-card-message">We unable to process payment for</div>
                        <div class="n-card-message">your recurring subscription</div>
                        <div class="n-card-link pt-1 pb-2">Contact us</div>
                    </div>
                    <div class="col-md-1 text-right d-flex justify-content-center align-items-center n-card-eye">
                        <v-icon>mdi-eye</v-icon>
                    </div>
                </div> -->
                <div class="n-card d-flex justify-content-between align-items-center mb-4">
                    <div class="n-card-left1 d-flex justify-content-center align-items-center">
                        <v-icon>mdi-flag</v-icon>
                    </div>
                    <div class="col-md-10 pl-0 py-3 d-flex align-items-center n-card-content">
                        <div class="pl-0 pr-4" v-if="item.logo">
                            <div class="n-card-gap d-flex justify-content-center align-items-center"><img class="notify-logo" :src="'http://indigo.cpx.ae/'+ item.logo"/></div>
                        </div>
                        <div>
                            <div class="n-card-title pb-1 pt-2">{{item.header}}</div>
                            <div class="n-card-message">{{item.message}}</div>
                            <!-- <div class="n-card-link1 pt-1 pb-2">Request Tracker</div> -->
                        </div>
                    </div>  
                    <div class="col-md-1 text-right d-flex justify-content-end align-items-center n-card-eye">
                      <div class="time-content">{{ item.created_at }}</div>
                        <!-- <v-icon v-if="item.redirection_url !== null && item.redirection_url !== ''" @click="handleOnRedirect(item.redirection_url)">mdi-eye</v-icon> -->
                    </div>
                </div>
              </div>
            </div>
            <div v-else class="d-flex mt-3 justify-content-center alert alert-primary" style="width: 100%;text-align: center;">
                <b>No Records</b>
            </div>
        </div>
	</div>
  </div>
  </div>
</template>
<script>
import Vue from "vue";
import ApiService from "../../core/services/api.service";
import { mapGetters } from "vuex";
import Loader from "@/view/content/Loader.vue";
import Swal from "sweetalert2";
export default {
  name: "notification",
  components: { ApiService,Loader },
  data() {
    return {
      showRequestToast: false,
      showRequestToastLimit: false,
      is_loading: false,    
      notificationList: [],  
    };
  },  
  mounted() {    
    Swal.close();
    this.affiliate_id = window.localStorage.getItem("affiliate_id");
    // this.getNotifications();
    this.is_loading = true;
    // setInterval(() => {
    //   this.$eventBus.$emit('setNotification');
    // }, 60000)
  },
  methods: {
     getNotifications(){
       let data = {
        affiliate_id: this.affiliate_id,
      };
      ApiService.post("notifications",data).then((response) => {
        this.notificationList = response.data.data.sort(function(x, y){
            return new Date(y.created_at).getTime() - new Date(x.created_at).getTime();
        })
        this.is_loading = false;
        console.log(this.notificationList,"notificationList");
        this.notificationList.map(item => {
           this.handleNotifyView(item);
        })
      }).catch(error => {
        if(error.response.status == 400){
          localStorage.clear();
          localStorage.removeItem("affiliate_id");
          window.location.reload();
          // Swal.fire({
          //   imageUrl: "media/images/logout-emoji.png",
          //   imageWidth: 165,
          //   imageHeight: 123,
          //   title: "Your Session is expired.",
          //   timer: 1500,
          //   timerProgressBar: true,
          //   showConfirmButton: false,
          // })
          // setTimeout(() => {
          //   window.location.reload();
          // }, 1500);
        }
        throw new Error(`[KT] ApiService ${error}`);
      });
     },
     handleNotifyView(data){
        let seenData = {
          affiliate_id: this.affiliate_id,
          id: data.id
        }
        if(data.is_seen !== true){
          ApiService.post("notification/seen",seenData).then((response) => {    
            if(response.data.result) {  
              this.getNotifications();   
              this.$eventBus.$emit('setNotification');          
            }
            // if(data.redirection_url) window.open(`http://${data.redirection_url}`,"_blank")
          }).catch(error => {
            if(error.response.status == 400){
              localStorage.clear();
              localStorage.removeItem("affiliate_id");
              window.location.reload();
              // Swal.fire({
              //   imageUrl: "media/images/logout-emoji.png",
              //   imageWidth: 165,
              //   imageHeight: 123,
              //   title: "Your Session is expired.",
              //   timer: 1500,
              //   timerProgressBar: true,
              //   showConfirmButton: false,
              // })
              // setTimeout(() => {
              //   window.location.reload();
              // }, 1500);
            }
            throw new Error(`[KT] ApiService ${error}`);
          });
        }
     },
     convertNumberToTimeAgo (number, short) {
        Date.parse(number)
        const now = +new Date()
        const timeAgo = now - number
        const ONE_YEAR = 3.154e10
        const ONE_MONTH = 2.628e9
        const ONE_DAY = 8.64e7
        const ONE_HOUR = 3.6e6
        const ONE_MINUTE = 60000
        console.log(short,'time----')

        if (timeAgo >= ONE_YEAR * 2) {
        return `${Math.floor(timeAgo / ONE_YEAR)} ${short ? 'yr' : 'years'} ago`
        } else if (timeAgo >= ONE_YEAR) {
        return `a ${short ? 'yr' : 'year'} ago`
        } else if (timeAgo >= ONE_MONTH * 2) {
        return `${Math.floor(timeAgo / ONE_MONTH)} ${short ? 'mo' : 'months'} ago`
        } else if (timeAgo >= ONE_MONTH) {
        return `1 ${short ? 'mo' : 'month'} ago`
        } else if (timeAgo >= ONE_DAY * 2) {
        return `${Math.floor(timeAgo / ONE_DAY)} days ago`
        } else if (timeAgo >= ONE_DAY) {
        return '1 day ago'} 

        else if (timeAgo >= ONE_HOUR * 2) {
        return `${Math.floor(timeAgo / ONE_HOUR)} ${short ? 'hr' : 'hours'} ago`
        } else if (timeAgo >= ONE_HOUR) {
        return `1 ${short ? 'hr' : 'hour'} ago`
        } else if (timeAgo >= ONE_MINUTE * 2) {
        return `${Math.floor(timeAgo / ONE_MINUTE)} ${short ? 'min' : 'minutes'} ago`
        } else if (timeAgo >= 0) {
        return `1 ${short ? 'min' : 'minute'} ago`
        } else {
        // timeAgo < 0 is in the future
        // console.error(`convertNumberToTimeAgo: number ${number} timeAgo ${timeAgo}, is date older than 1970 or in the future?`)
        return 'Just now'
        }
      },
     handleOnRedirect(data){
        if(data) window.open(`http://${data}`,"_blank")
     }
  },  
  computed: {
    ...mapGetters(["layoutConfig"]),
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },
  },
};
</script>

<style lang="scss">
.time-content{
  white-space: nowrap;
}
.notify-logo{
    width: 85px;
    height: 85px;
}
.n-main-head{
    font-size: 14px;
    font-weight: 600;
}
.n-card-title{
    font-size: 12px;
    color: #000;
    font-weight: 600;
}
.n-card-message{
    font-size: 13px;
    color: #000;
}
.n-card-link{
    font-size: 13px;
    color: #f16063;
    font-weight: 600;
}
.n-card-link1{
    font-size: 13px;
    color: #397ef5;
    font-weight: 600;
    cursor: pointer;
}
.n-card{
    background-color: #f3f6f9;
    height: 100px;
    border-radius: 10px;
    overflow: hidden;
}
.n-card:hover, .n-card-show{
    opacity: 0.7;
}
.n-card .mdi-eye{
    color: #11315b !important;
    cursor: pointer;
}
.n-card-left{
    background-color: #f5dde1;
    height: 100%;
}
.n-card-left .mdi-comment-remove-outline{
    color: #f16063 !important;
}
.n-card-left1{
    width: 50px;
    background-color: #d5e5fe;
    height: 100%;
}
.n-card-left1 .mdi-flag{
    color: #397ef5 !important;
}
.n-card-gap{
    // height: 100%;
    // width: 90px;
    border-radius: 10px;
    // background-color: #254977;
    color: #fff;
    font-family: 'Flaticon2';
    font-size: 32px;
    overflow: hidden;
}
.offer-sort-btn {
    font-size: 13px !important;
    font-weight: normal !important;
    line-height: normal !important;
    color: inherit !important;
    background-color: transparent !important;
}
.offer-container{
    text-align: center !important;
    min-width: fit-content !important;
}
.offer-container .dropdown-item:active{
  background-color: #EBEDF3 !important;
}
.offer-link:hover{
  background-color: transparent !important;
}
.offer-sort-btn {
    font-size: 13px !important;
    font-weight: normal !important;
    line-height: normal !important;
    color: inherit !important;
    background-color: transparent !important;
}
.offer-text{
    margin-right: 10px !important;
}
.offer-link{
    padding: 0.25rem 0.25rem !important;
}
.offer-link:hover{
    color: #0f2753 !important;
}
.h-notify-count{
  background: #f56160;
  color: #fff;
  border-radius: 10px;
  width: 25px;
  font-size: 12px;
}
.btn-mobile-profile ul{
  width: auto !important;
}
.col-xl-12.profileInfo.pt-0 {
    margin-top: 0px;
    position: relative;
    z-index: unset;
}
.container-fluid{
	padding:0px !important;
}

@media screen and (max-width: 767.9px) {   
    .n-card{
        flex-direction: column;
        height: auto;
    } 
    .n-card-left, .n-card-left1, .n-card-eye{
        padding: 10px 0px;
    }
    .n-card-eye{
        padding-top: 0px;
        justify-content: center !important;
    }
    #kt_content > .d-flex.flex-column-fluid {
        position: relative;
        top: 0px !important;
        margin-bottom: 100px;
    }
    .n-card-left1{
      width: 100%;
    }
    .n-card-content{
      padding-left: 0.75rem !important; 
    }
}
 @media only screen and (max-width: 768px) {
    #kt_content{
        min-height: 100vh !important;
        padding-top: 0px;
        height:  unset !important;
    }
}
.body-content{
  position: unset !important;
  width:100%;
}
#kt_content{
  min-height: 100vh !important;
  padding-top: 50px;
  height:  unset !important;
}
.select {
  color: #000;
  font-weight: 700;
}
@media only screen and (min-width: 1200px) {
  .last_refreshed {
    color: black !important;
    // margin-top: 220px;
    icon {
      color: white;
    }
    span {
      margin-left: 1rem;
    }
  }
  .o-fixed-td{
      white-space: unset !important;
  }
  
}
.ddd {
  z-index: 0;
  margin-top: -2.5rem !important;
}
@media only screen and (max-width: 1200px) {
  .last_refreshed {
    color: #19248d;
    margin-top: 0px !important;
    span {
      margin-left: 1rem;
    }
  }
}
@media only screen and (max-width: 1600px) {
  .last_seen {
    color: white;
    float: right;
    span {
      margin-left: 1rem;
    }
  }
}

//for screens bigger then mobile
@media only screen and (min-width: 1600px) {
  .last_seen {
    color: black;
    float: right;
    span {
      margin-left: 1rem;
    }
  }
}

// for mobile
@media only screen and (max-width: 1200px) {
  .offer-text{
      margin-right: 0px !important;
  }
#kt_content .ddd {
    display: none;
}

.header-mobile-fixed .header-mobile {
    height: 350px !important;
}

#kt_content .container-fluid {
    margin-top: -240px;
    position: relative;
    // z-index: 99;
}
}
@media only screen and (max-width: 600px) {
  .toggle_buttons {
    width: 6rem !important;
    font-size: 0.8rem !important;
  }
}
@media only screen and (max-width: 450px) {
  .toggle_buttons {
    width: 7rem !important;
    height: 4rem !important;
    font-size: 0.85rem !important;
    margin: 0.1rem !important;
  }
}
@media only screen and (max-width: 363px) {
  .toggle_buttons {
    width: 6rem !important;
    height: 2.5rem !important;
    font-size: 0.7rem !important;
  }
}
@media only screen and (max-width: 305px) {
  .toggle_buttons {
    width: 4rem !important;
    height: 3rem !important;
    font-size: 0.5rem !important;
  }
}

@media(max-width:767px){
  .bar span {
    font-size: 11px;
}  
}

@media(min-width:1200px){
  p.last_refreshed {
    color: #000 !important;
    // margin-top: 110px !important;
    position: relative;
    z-index: 99;
    padding-left: 0;
    font-weight: inherit;
    font-size: 20px;
    margin-bottom: 8px;
    width: 70%;;
    margin-top:0 !important;
}

p.last_refreshed + .btn-div.mt-5 {
    position: relative;
    z-index: 999;
    padding-left: 0;
    margin-top: 0 !important;
    width: 70%;
}

p.last_refreshed + .btn-div.mt-5 .export-btn {
    display: none;
}

.content-wrapper > div > .row {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 0 !important;
}

#kt_content > div > .container-fluid {
    max-width: 1185px;
    width: 100%;
}
#kt_content > .d-flex.flex-column-fluid {
    position: relative;
    top: 100px;

}
}
@media(max-width:1200px){
  #kt_content .ddd {
    display: none;
}

.header-mobile-fixed .header-mobile {
    height: 350px !important;
}

#kt_content .container-fluid {
    margin-top: -240px;
    position: relative;
    // z-index: 99;
}
#kt_content .container-fluid {
    margin-top: -288px;
}
  #kt_content .ddd {
      display: block;
      padding-left: 8px;
    }

    #kt_content .ddd p.last_refreshed {
        display: none;
    }

    #kt_content .ddd .export-btn {
        display: none;
    }

    #kt_content .ddd span {
        display: none;
    }
    #kt_content .ddd button.btn.btn-secondary {
      display: block !important;
      margin-top: 15px;
    }
  .header-mobile-fixed .header-mobile {
      height: 410px !important;
  }

  #kt_content .container-fluid {
      margin-top: -286px;
      position: relative;
    //   z-index: 99;
      width: 100%;
      padding-left: 1rem !important;
      padding-right: 1rem !important;
  }
#parent .toggle_buttons {
    width: auto !important;
    margin-right: 30px !important;
}
#kt_content .container-fluid {
    margin-top: -275px !important;
}
}
#kt_content > .d-flex.flex-column-fluid {
    position: relative;
    top: 100px;
    margin-bottom: 100px;
}
</style>